import React, { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import DashboardItem from "../../data/header.json";

import menuImg from "../../public/images/menu-img/JARVIS_Release_notes.png";
import { useAppContext } from "@/context/Context";

const Nav = () => {
  const router = useRouter();
  const { showItem, setShowItem } = useAppContext();
  const { activeMobileMenu, setActiveMobileMenu } = useAppContext();

  const isActive = (href) => router.pathname === href;

  return (
    <>
      <ul className="mainmenu">
        <li>
          <Link href="/">Home</Link>
        </li>
        {/* <li className="with-megamenu has-menu-child-item position-relative">
          <a href="#about">About</a>
        </li> */}
        <li className="with-megamenu has-menu-child-item position-relative">
          <a
            href="#jarvis"
            onClick={() => setShowItem(!showItem)}
            className={`${!showItem ? "open" : ""}`}
          >
            JARVIS
          </a>
          <div
            className={`rainbow-megamenu right-align with-mega-item-2 ${
              showItem ? "" : "d-block"
            }`}
          >
            <div className="wrapper p-0">
              <div className="row row--0">
                <div className="col-lg-6 single-mega-item">
                  <h3 className="rbt-short-title">DASHBOARD PAGES</h3>
                  <ul className="mega-menu-item">
                    {DashboardItem &&
                      DashboardItem.navDashboardItem.map((data, index) =>
                        data.link === "" ? (
                          <li key={index}>
                            <a>{data.text}</a>
                          </li>
                        ) : (
                          <li key={index}>
                            <a
                              href={data.link}
                              target={
                                data.text === "Release Notes" ? "" : "_blank"
                              }
                              rel="noopener noreferrer"
                            >
                              {data.text}
                            </a>
                            {/* <Link
                            href={data.link}
                            passHref
                            className={isActive(data.link) ? "active" : ""}
                          >
                            <a target="_blank">{data.text}</a>
                          </Link> */}
                          </li>
                        )
                      )}
                  </ul>
                </div>
                <div className="col-lg-6 single-mega-item">
                  <div className="header-menu-img">
                    <Image src={menuImg} alt="Menu Split Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <Link href="/developers">Developers</Link>
        </li>
        <li>
          <Link href="/company">Company</Link>
        </li>
        <li>
          <Link href="/news">News</Link>
        </li>
        <li>
          <Link href="https://jarvis.viatick.com/app/user/login">Sign In</Link>
        </li>
        {!activeMobileMenu ? (
          <li>
            <Link href="/contact-us">Contact Us</Link>
          </li>
        ) : null}
        {/* <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
      </ul>
    </>
  );
};

export default Nav;
