import Head from "next/head";

const PageHead = ({ title, description }) => {
  return (
    <>
      <Head>
        <title>{"Viatick | " + title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content="Viatick" key="title" />
        <meta name="title" content="Viatick" key="title" />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content="Bluetooth, BLE5, IOT, IIOT, AIOT, Startup, Business, Company, Corporate, Blesig, Jarvis, BMS, Buzzvox, SafeEvent, Smart Toilet, Smart Building"
        />
        <meta property="og:image" content="/images/logo/viatick-logo-1.png" />
      </Head>
    </>
  );
};

export default PageHead;
